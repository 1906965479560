import consumer from "./consumer"

consumer.subscriptions.create("PresenterChannel", {
    connected: function () {
        // Called when the subscription is ready for use on the server
    },

    disconnected: function () {
        // Called when the subscription has been terminated by the server
    },

    received: function (data) {
        // Called when there's incoming data on the websocket for this channel
        window.console.log(data);

        /* display new message on tabletview */
        if (data.action == "showNewMsg" && (page == "tabletview" || page == "smsview" || page == "presenter")) {

            console.log("showNewMsg on Tabletmode: ", data);
            // cases:
            // data.sms.newnumber == 1 --> put it as first
            // > 1
            // find previous element to add after
            var smsnumber = data.sms.newnumber;
            console.log("smsnumber: " + smsnumber);
            var prevnumber = smsnumber - 1;
            if (page !== "presenter") {
                var prevElement = $('div[data-smsnumber=' + prevnumber + ']');
                console.log("prevElement", typeof prevElement);
                while (prevElement.length == 0) {  // element nicht vorhanden, weiter retour
                    prevnumber = prevnumber - 1;
                    var prevElement = $('div[data-smsnumber=' + prevnumber + ']');

                }
                console.log("prevnumber: " + prevnumber);
            }
            if (page == "tabletview") {
                var myurl = '/tabletview/tabletsms?newnumber=' + smsnumber;
            }
            else {
                var myurl = '/smsview/singlesms?newnumber=' + smsnumber;
            }
            $.ajax({
                url: myurl,
                prevNumber: prevnumber,
				smsnumber: smsnumber,
                msgdata:  data,
                page: page
            }).done(function (data) {
            	console.log(data)
            	// zuerst die SMS an der alten Stelle löschen aus dem DOM
            	//$('div[data-smsnumber=' + this.smsnumber + ']').remove();
            	// dann an der neuen Stelle einfügen
                if (this.page !== "presenter") {
                    let el = $('div[data-smsnumber=' + this.smsnumber + ']')
                    if (el.length !== 0) {
                        $('div[data-smsnumber=' + this.smsnumber + ']').replaceWith(data); // Inhalt tauschen
                    } else {
                        $('div[data-smsnumber=' + this.prevNumber + ']').after(data);
                    }
		   if (page == "tabletview") {
			$('div[data-katnumber=' + this.msgdata.kat.id + '] div.header').html(this.msgdata.kat.numsms + '&nbsp;<i class="fa fa-comment md-message"></i>');
		   }
                }
                $('#presenterSMS #smstext').text(this.msgdata.sms.content);
                $('#presenterSMS #katname').text(this.msgdata.kat.name);
                $('#smsCounter button').text(this.msgdata.numsms+ (this.msgdata.numsms == 1? ' Frage':' Fragen'));
                $('#presenterSMS').fadeIn();
                setTimeout(function () {
                    $('#presenterSMS').fadeOut();
                }, 3000);
                $('#teaserarea').html(data);
                $('#teaserarea').show();
                setTimeout(function () {
                    $('#teaserarea').fadeOut();
                }, 3000);

            });

        }
        else if ((data.action == "removeMsg" ) && (page !== "editor") && (page !== "smsmode")) {
            $('div[data-smsnumber=' + data.smsnewnumber + ']').remove(); // element löschen, wenn vorhanden am frontend
            $('div[data-smsnumber=' + data.smsid + ']').remove(); // element löschen, wenn vorhanden am frontend
            $('div[data-katnumber=' + data.katid + '] div.header').html(data.katnumsms + '&nbsp;<i class="fa fa-comment md-message"></i>');
            $('#smsCounter button').text(data.numsms+ (data.numsms == 1? ' Frage':' Fragen'));
        }

    }
});
