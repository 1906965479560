import consumer from "./consumer"

consumer.subscriptions.create("MessagesChannel", {
  connected: function() {
    // Called when the subscription is ready for use on the server
  },

  disconnected: function() {
    // Called when the subscription has been terminated by the server
  },

  received: function(data) {
    // Called when there's incoming data on the websocket for this channel
    console.log("message received from server: ", data);

    if (page == "editor") {
      console.log("Editor Reload");
	location.href=location.href;
    }
  }
});

