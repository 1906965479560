require('jquery-ui/ui/widget');

require('jquery-ui/ui/data');

require('jquery-ui/ui/scroll-parent');

require('jquery-ui/ui/widgets/draggable');

require('jquery-ui/ui/widgets/droppable');

require('jquery-ui/ui/widgets/mouse');

require('jquery-ui/themes/base/core.css');

require('jquery-ui/themes/base/draggable.css');