import consumer from "./consumer"

consumer.subscriptions.create("DisplaysChannel", {
    connected: function () {
        // Called when the subscription is ready for use on the server
    },

    disconnected: function () {
        // Called when the subscription has been terminated by the server
    },

    received: function (data) {
        console.log("App.displays received: DISPLAYACTION: " + data.action + ', page: ' + page);
        console.log("DATA:", data);

        if (data.action == "reloadAllDevices") {
            location.href = location.href
        }
        else if ((data.action == "reloadKatList") && (page !== "presenter") && (page !== "smsmode")) {
            location.href = location.href
        } else if (data.action == "TabletNewMsg") {
           /*if (page == "tabletview") {
                // auf tablet soll die neu angelegte kategorie angezeigt werden, und dann wieder verschwinden
                let myurl = '/tabletview/tabletsms?newnumber=' + data.sms.newnumber
                $.ajax({
                    url: myurl,
                    prevNumber: data.sms.newnumber
                }).done(function (data) {
                    $('#teaserarea').append(data);
                    $('#teaserarea').show();
                    setTimeout(function () {
                        $('#teaserarea').fadeOut();
                    }, 3000);
                });
            }
           */
        }
         else if (data.action == "ShowNewKat" && (page == "tabletview" || page == "smsview")) {
            console.log("Kategorie wurde hinzugefuegt", data);
            $.ajax({
                url: '/' + page + '/singlekat?id=' + data.kat.id,
                page: page
            }).done(function (html) {
                $('#second2 ').append(html);
                if (page == "tabletview") {
                    // auf tablet soll die neu angelegte kategorie angezeigt werden, und dann wieder verschwinden
                    $('#teaserarea').append(html);
                    $('#teaserarea').show();
                    setTimeout(function () {
                        $('#teaserarea').fadeOut();
                    }, 10000);
                }
            });
        } else if (data.action == "closeDisplayKat") {
            console.log("closeDisplayKat: ", data);
            $('#fullscreenblack > div > div > div.card-head > div > div > a').trigger('click.rails');
            $('.smsview div[kat-id=' + data.katid + '] > div.card-head.card-head > div > div > a.btn-setread > i').attr('class', 'fa fa-check');
            $('div[kat-id=' + data.katid + ']').addClass('read-state');
            //$('#ipadview div[kat-id='+data.katid+']').each(function() {$(this).removeClass('tabletview-unread-state'); });
            $('.ipadview div[kat-id=' + data.katid + ']').removeClass('tabletview-unread-state');
            $('.ipadview div[kat-id=' + data.katid + ']').addClass('tabletview-read-state');

            //$('#ipadview div[kat-id='+data.katid+']').each(function() {$(this).addClass('tabletview-read-state'); });
            //$('#ipadview div[kat-id='+data.katid+'].card > div > div.card-head > div > div > a.btn-setread  > i').each(function() { $(this).text('md md-check'); });
            //$('.ipadview div[kat-id='+data.katid+'].card > div > div.card-head > div > div > a.btn-setread  > i').attr('class','md md-check');
            $('.ipadview div[kat-id=' + data.katid + ']  > div.card-head > div > div > a.btn.btn-icon-toggle.btn-setread.button-0 > i').attr('class', 'md md-check');
            $('.smsview div[kat-id=' + data.katid + ']  > div > div.card-head > div > div >a.btn-setread > i').attr('class', 'fa fa-check');
            if (page == "smsmode") location.href = location.href;
            $('#selectedDisplay').html('');
        } else if (data.action == "closeDisplayKat_unread") {
            console.log("closeDisplayKat_unnread: ", data);
            $('#fullscreenblack > div > div > div.card-head > div > div > a').trigger('click.rails');
            $('.smsview div[kat-id=' + data.katid + '] > div.card-head.card-head > div > div > a > i').attr('class', 'fa fa-check');
            $('div[kat-id=' + data.katid + ']').removeClass('read-state');
            $('.ipadview div[kat-id=' + data.katid + ']').removeClass('tabletview-read-state');
            $('.ipadview div[kat-id=' + data.katid + ']').addClass('tabletview-unread-state');

            //$('#ipadview div[kat-id='+data.katid+'].card > div > div.card-head > div > div > a.btn.btn-setread > i').each(function() { $(this).text('md md-close'); });
            //$('.ipadview div[kat-id='+data.katid+'].card > div > div.card-head > div > div > a.btn-setread > i').attr('class','md md-close');
            $('.ipadview div[kat-id=' + data.katid + ']  > div.card-head > div > div > a.btn.btn-icon-toggle.btn-setread.button-0 > i').attr('class', 'md md-close');
            $('.smsview div[kat-id=' + data.katid + ']  > div > div.card-head > div > div >a.btn-setread > i').attr('class', 'fa fa-close');

            if (page == "smsmode") location.href = location.href;
        } else if ((data.action == "setPRESENTER") && (page == "smsview")) {
            location.href = "/presenter";
        } else if (data.action == "setSMSVIEWKAT" && page == "presenter") {
            location.href = "/smsview#kategorie-tab";
        } else if (data.action == "setSMSVIEWSMS" && page == "presenter") {
            location.href = "/smsview#nachrichten-tab";
        } else if (data.action == "setSMSVIEWKAT" && page == "smsview") {
            // #kategorie-tab
            $('#kategorie-tab').trigger('click');
        } else if (data.action == "setSMSVIEWSMS" && page == "smsview") {
            $('#nachrichten-tab').trigger('click');
        } else if (data.action == "closeDisplaySMS") {
            console.log('closeDisplaySMS triggered');
            $('#fullscreenblack  div.card-head  a').trigger('click.rails');
            //#katsms > div > div.card-head.style-danger > div > div > a
            $('div[sms-id=' + data.smsid + '].card  > div.card-head.card-head > div > div > a > i').attr('class', 'fa fa-check');
            // tabletview auch aktualisieren

            $('#ipadview div[sms-id=' + data.smsid + ']').addClass('tabletview-read-state');
            $('#ipadview div[sms-id=' + data.smsid + '].card > div > div.card-head > div > div > a.btn > i').text('md md-check');
            var el = 'div[sms-id=' + data.smsid + ']';
            console.log(el);
            $(el).addClass('read-state');
            //
            if (page == "smsmode") {
                $('#selectedDisplay').html('');
                //location.href = location.href
                console.log(data)
                if (data.next_sms !== null) {
                    $('#smssslection').val(data.next_sms.newnumber)
                    if ($('div[data-id=' + data.smsid + '] button').hasClass('btn-danger')) {
                        $('div[data-id=' + data.smsid + '] button').removeClass('btn-danger')
                        $('div[data-id=' + data.smsid + '] button').addClass('btn-success')
                    } else {
                        $('div[data-id=' + data.smsid + '] button').removeClass('btn-success')
                        $('div[data-id=' + data.smsid + '] button').addClass('btn-danger')
                    }
                    setTimeout(function () {
                        console.log("submit klicken")
                        $('#katselsubmit').click()
                    }, 100);
                }
            }
      }
      else if ((data.action == "toggleSMS") && (page == "smsmode")) {
            if ($('div[data-id=' + data.smsid + '] button').hasClass('btn-danger')) {
                $('div[data-id=' + data.smsid + '] button').removeClass('btn-danger')
                $('div[data-id=' + data.smsid + '] button').addClass('btn-success')
            } else {
                $('div[data-id=' + data.smsid + '] button').removeClass('btn-success')
                $('div[data-id=' + data.smsid + '] button').addClass('btn-danger')
            }
      } else if (data.action == "closeDisplaySMS_unread") {
            $('#fullscreenblack > div > div > div.card-head > div > div > a').trigger('click.rails');
            $('div[sms-id=' + data.smsid + '].card  > div.card-head.card-head > div > div > a > i').attr('class', 'fa fa-close');
            // tabletview auch aktualisieren

            $('#ipadview div[sms-id=' + data.smsid + ']').addClass('tabletview-read-state');
            $('#ipadview div[sms-id=' + data.smsid + '].card > div > div.card-head > div > div > a.btn > i').text('md md-close');
            var el = 'div[sms-id=' + data.smsid + ']';
            console.log(el);
            $(el).removeClass('read-state');
            if (page == "smsmode") {
                $('#selectedDisplay').html('');
                location.href = location.href;
            }
        } else if (page == "smsmode" && data.action == "update") {
            console.log("smsmode - toggleclusterbuttons:", data)

            if(data.data[0].displaytype == "cluster") {
                // Formular querx
                if (data.data[0].smsmodetarget == "single") {
                    if ($('.smsmode #clusterselsubmit').hasClass('showoff')) {
                        $('.smsmode #clusterselsubmit').val('Los!');  
                        $('.smsmode #clusterselsubmit').addClass('showon');
                        $('.smsmode #clusterselsubmit').removeClass('showoff');
                    }
                    else {
                        $('.smsmode #clusterselsubmit').val('Off');  
                        $('.smsmode #clusterselsubmit').addClass('showoff');
                        $('.smsmode #clusterselsubmit').removeClass('showon');
                    }
                }
                // list buttons
                else {
                    //$('.smsmode .clusterLosBtn').removeClass('showon');
                    //$('.smsmode .clusterLosBtn').removeClass('showoff');
                    //$('.smsmode .clusterLosBtn').addClass('showon');
                    if ($('.smsmode button[data-id="'+data.data[0].smsmodetarget+'"].clusterLosBtn').hasClass('showoff')) {
                        //$('.smsmode .clusterLosBtn').removeClass('showoff');
                        $('.smsmode button[data-id="'+data.data[0].smsmodetarget+'"].clusterLosBtn').removeClass('showoff');
                        $('.smsmode button[data-id="'+data.data[0].smsmodetarget+'"].clusterLosBtn').addClass('showon');
                    } 
                    else {
                        $('.smsmode button[data-id="'+data.data[0].smsmodetarget+'"].clusterLosBtn').addClass('showoff');
                        $('.smsmode button[data-id="'+data.data[0].smsmodetarget+'"].clusterLosBtn').removeClass('showon');
                    }
                }
            }


        } else if (page == "smsview" && data.action == "update") {
            // Called when there's incoming data on the websocket for this channel
            console.log("DisplaysChannel received unspecified request from server: ", data.data);
            var targetElement = "";
            var targetTab = '';
            console.log("data.data[0].katselect", data.data[0].katselect);
            console.log("data=", data);
            if (typeof data.data !== "undefined") {
                if (data.data[0].displaytype === "sms") {
                    console.log('sms anzeige');
                    targetElement = '.tab-pane div[sms-id=' + data.data[0].displayrecord[0].id + '].card';
                    targetTab = '#nachrichten-tab';
                    $('#katsms').attr('style', '');
                } 
                else if (data.data[0].displaytype === "kat") {
                    console.log('kategorie anzeige');
                    targetElement = '.tab-pane div[kat-id=' + data.data[0].katselect + '].card';
                    targetTab = '#kategorie-tab';
                }
                else if(data.data[0].displaytype == "cluster") {
                    if ($('#nachrichtDetail').css('display')!=="none") {
                        $('#nachrichtDetail').hide();
                        $('#nachrichtDetail #fullscreenblack').html('<div class="col-md-6" id="katsms"></div>');
                    }
                    else {
                        var messages = data.data[0].displayrecord;
                        // darstellung löschen
                        var dataset = '<div class="heading"><h4>' + data.data[0].heading + '</h4></div>';
                        targetTab = '#first2';
                        console.log("targetTab: " + targetTab);
                        $(targetTab).trigger('click');
                        // #first2 > div > div:nth-child(2) > div
                        messages.forEach(function(item) {
                            console.log("Message: " + item);
                            var el = $('#first2 div[data-smsnumber="' + item + '"]').html();
                            dataset = dataset + el;
                        });
                        dataset = '<div class="clustersms">' + dataset + '</div>';
                        $('#nachrichtDetail #fullscreenblack').html(dataset);
                        //$('#nachrichtDetail #fullscreenblack').css('width','100vw');
                        //$('#nachrichtDetail #fullscreenblack').css('height','100vh');
                        $('#nachrichtDetail #fullscreenblack div.card').removeClass('read-state');
                        $('#nachrichtDetail').show()

                        $('#nachrichtDetail #fullscreenblack').click(function(){
                            $('#nachrichtDetail').hide();
                            $('#nachrichtDetail #fullscreenblack').html('<div class="col-md-6" id="katsms"></div>');
                        });
                    }
                }
                console.log("targetTab: " + targetTab);
                $(targetTab).trigger('click');
                setTimeout(function () {
                    $(targetElement).trigger('click');
                    if (page == "smsview") scrollTo(0, 0);
                }, 100);
            }

        }
    }
});
